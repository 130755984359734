import React from 'react';
import Reveal from 'react-reveal/Reveal';
import Img from 'gatsby-image/withIEPolyfill';
import { graphql, useStaticQuery } from 'gatsby';
import Sectitle from '../../Title/Sectitle';

const OurClients = () => {
  const imgData = useStaticQuery(graphql`
    query {
      contentfulIconList(title: {eq: "Our Clients"}) {
        logos {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
      }
    }
  `);

  const { logos } = imgData.contentfulIconList;

  return (
    <section className="sec_pad">
      <div className="container">
        <div className="agency_featured_content wow fadeInLeft pr_70 pl_70" data-wow-delay="0.6s">
          <Reveal effect="fadeInRight">
            <div className="row app_featured_content">
              <Sectitle sClass="sec_title text-center mb_70 w-100" Title="Our Clients" tClass="t_color3" />
              {logos.map((logo) => {
                if (logo.fluid) {
                  return ( 
                    <div className="col-lg-2 col-md-3 col-sm-4 col-6 px-3 py-3">
                      <Img fluid={logo.fluid} objectFit="contain" className="mw-75 h-100"/>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </Reveal>
        </div>
      </div>
    </section>
  );
}

export default OurClients;
